<template>
    <div class="experienceBox">
        <div class="itmeBox">
            <div class="title">
                <p class="star">*</p>
                <p>{{ type == 'wx' ? '体验者微信号：' : type == 'ali' ? '体验者支付宝号：' : '' }}</p>
            </div>
            <el-input :placeholder="type == 'wx' ? '请输入正确的微信号' : type == 'ali' ? '请输入正确的支付宝' : ''"
                v-model.trim="experiencerWx"></el-input>
        </div>
        <div class="itmeBox">
            <div class="title"></div>
            <div class="operation">
                <div :style="{ marginRight: type == 'ali' ? '20px' : '' }" class="addOperation" @click="addWxAccout">添加
                </div>
                <div v-if="type == 'wx'" class="outOperation" @click="deleteAccout(0)">解绑</div>
                <span class="manyOut" @click="showManyDel(0)">批量解绑</span>
            </div>
        </div>
        <div class="itmeBox">
            <div class="title">
                <p>二维码：</p>
            </div>
            <div class="codebox">
                <div class="code">
                    <img v-if="QRcode != ''" :src="QRcode" alt="" />
                    <p v-else>上传小程序后可生成体验码</p>
                </div>
                <p v-if="type == 'wx'">小程序未上线前，可以添加体验者微信号，刷新二维码体验小程序</p>
                <p v-else>小程序未上线前，可以添加体验者支付宝，刷新二维码体验小程序</p>
            </div>
        </div>
        <el-dialog :modal="false" :visible.sync="manyOut" width="52%" :modal-append-to-body="true">
            <template #title>
                <div>
                    <h3>批量解绑</h3>
                </div>
            </template>
            <div class="contant">
                <div class="itmeBox contantbox" v-if="type == 'wx'">
                    <div class="title">
                        <p>体验者微信号：</p>
                    </div>
                    <el-input placeholder="请输入正确的微信号" class="experiencerWxModal"
                        v-model.trim="experiencerWxModal"></el-input>
                    <div class="search" @click="getTryList(1)">搜索</div>
                </div>
                <el-table v-if="type == 'wx'" height="480" :header-cell-style="{ 'background-color': '#F8F9FA' }"
                    ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
                    @selection-change="handleSelectionChange">
                    <el-table-column type="selection" width="150"></el-table-column>
                    <el-table-column label="绑定微信号" width="220">
                        <template slot-scope="scope">{{ scope.row.wechat_id }}</template>
                    </el-table-column>
                    <el-table-column prop="create_time" label="绑定时间"></el-table-column>
                </el-table>
                <el-table v-if="type == 'ali'" height="480" :header-cell-style="{ 'background-color': '#F8F9FA' }"
                    ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
                    @selection-change="handleSelectionChange">
                    <el-table-column align="center" type="selection" width="50"></el-table-column>
                    <el-table-column align="center" label="user_id">
                        <template slot-scope="scope">{{ scope.row.user_id }}</template>
                    </el-table-column>
                    <el-table-column align="center" prop="gmt_invite" width="200" label="绑定时间"></el-table-column>
                    <el-table-column align="center" prop="logon_id" width="150" label="支付宝账号"></el-table-column>
                    <el-table-column align="center" prop="nick_name" width="150" label="昵称"></el-table-column>
                </el-table>
                <Paging class="paging" :total="total" :page="page" :pageNum="pageNum" @updatePageNum="updateData"></Paging>
            </div>
            <template #footer>
                <el-button @click="manyOut = false">取消</el-button>
                <el-button type="primary" @click="deleteAccout(1)">批量解绑</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import Paging from '../../../../components/paging';
export default {
    props: {
        appstatus: {
            type: Number,
            default: () => 0,
        },
        type: {
            type: String,
            default: '',
        },
        storeId: {
            type: Number,
            default: 0,
        },
    },
    components: {
        Paging,
    },
    data () {
        return {
            total: 100,
            page: 1,
            pageNum: 10,
            experiencerWxModal: null,
            experiencerWx: null,
            manyOut: false,
            tableData: [],
            multipleSelection: [],
            QRcode: '',
        };
    },
    methods: {
        // 支付宝小程序生成体验版 -> 后台处理
        async aliexperienceCreate () {
            let that = this;
            let res = await this.$axios.post(this.$api.set.experienceCreate);
            if (res.code == 0) {
                this.getQRcode();
            } else {
                this.$message.error(res.result);
            }
        },
        // 添加绑定体验者
        addWxAccout () {
            let that = this;
            let obj = {};
            if (this.type == 'wx') {
                obj.wechatId = this.experiencerWx;
                if (this.appstatus < 2) {
                    this.$message.warning('请先上传小程序');
                    return;
                }
                if (!this.experiencerWx) {
                    this.$message({
                        message: '请填写微信号',
                        type: 'warning',
                    });
                    return;
                }
            } else if (this.type == 'ali') {
                obj.ali_account = this.experiencerWx;
                // 开发者DEVELOPER 体验者EXPERIENCER
                obj.type = 'EXPERIENCER';
                if (this.appstatus < 2) {
                    this.$message.warning('请先上传小程序');
                    return;
                }
                if (!this.experiencerWx) {
                    this.$message({
                        message: '请填写支付宝号',
                        type: 'warning',
                    });
                    return;
                }
            }
            let path = this.storeId ? this.$api.serviceProvider.wxtesterBind : this.$api.set.wxtesterBind;
            if (this.storeId) {
                obj.store_id = this.storeId
            }
            this.$axios.post(this.type == 'wx' ? path : this.type == 'ali' ? this.$api.set.bindMember : '', obj).then(res => {
                if (res.code == 0) {
                    this.experiencerWx = '';
                    this.$message({
                        message: '绑定成功',
                        type: 'success',
                    });
                } else {
                    this.$message({
                        message: `${res.msg}`,
                        type: 'error',
                    });
                }
            });
        },
        // 解绑体验者
        deleteAccout (status) {
            if (this.appstatus < 2) {
                this.$message.warning('请先上传小程序');
                return;
            }
            let that = this;
            let obj = null;
            let wechatIdArr = [];
            // 解绑一个
            if (status == 0) {
                if (this.type == 'wx') {
                    if (!this.experiencerWx) {
                        this.$message({
                            message: '请填写微信号',
                            type: 'warning',
                        });
                        return;
                    }
                    obj = {
                        wechatId: that.experiencerWx,
                    };
                } else if (this.type == 'ali') {
                    if (!this.experiencerWx) {
                        this.$message({
                            message: '请填写支付宝号',
                            type: 'warning',
                        });
                        return;
                    }
                    obj = {
                        user_id: that.experiencerWx,
                    };
                }
            }
            // 批量解绑
            if (status == 1) {
                if (this.type == 'wx') {
                    if (this.multipleSelection == 0) {
                        this.$message({
                            message: '请勾选要取消绑定的微信号',
                            type: 'warning',
                        });
                        return;
                    }
                    this.multipleSelection.map(item => {
                        wechatIdArr.push(item.wechat_id);
                    });
                    obj = {
                        wechatId: wechatIdArr,
                    };
                } else if (this.type == 'ali') {
                    if (this.multipleSelection == 0) {
                        this.$message({
                            message: '请勾选要取消绑定的支付宝号',
                            type: 'warning',
                        });
                        return;
                    }
                    if (this.multipleSelection.length == 1) {
                        obj = {
                            user_id: this.multipleSelection[0].user_id,
                        };
                    } else {
                        this.multipleSelection.map(item => {
                            wechatIdArr.push(item.user_id);
                        });
                        obj = {
                            user_id: wechatIdArr,
                        };
                    }
                }
            }
            let path = this.storeId ? this.$api.serviceProvider.wxtesterUnbind : this.$api.set.wxtesterUnbind;
            if (this.storeId) {
                obj.store_id = this.storeId
            }
            this.$axios.post(this.type == 'wx' ? path : this.type == 'ali' ? this.$api.set.membersDelete : '', obj).then(res => {
                if (res.code == 0) {
                    that.$message({
                        message: `解绑成功`,
                        type: 'success',
                    });
                    that.getTryList(0);
                } else {
                    that.$message({
                        message: `${res.result.msg}`,
                        type: 'error',
                    });
                }
            });
        },
        // 打开批量删除弹框
        showManyDel () {
            if (this.appstatus < 2) {
                this.$message.warning('请先上传小程序');
                return;
            }
            this.getTryList();
            this.manyOut = true;
        },
        // 获取表格选中的行数
        handleSelectionChange (selection) {
            this.multipleSelection = selection;
        },
        // 点击分页 查询分页数据
        updateData (val, status) {
            if (status == 0) {
                this.pageNum = val;
            } else {
                this.page = val;
            }
            this.getTryList();
        },
        // 获取体验者列表
        getTryList (status) {
            let that = this;
            let obj = { page: that.page, rows: that.pageNum };
            if (status == 1) {
                this.page = 1;
                if (this.type == 'wx') {
                    obj = {
                        wechat_id: that.experiencerWxModal,
                    };
                }
            }
            let path = this.storeId ? this.$api.serviceProvider.testerList : this.$api.set.testerList;
            if (this.storeId) {
                obj.store_id = this.storeId
            }
            this.$axios.post(this.type == 'wx' ? path : this.type == 'ali' ? this.$api.set.membersQuery : '', obj).then(res => {
                if (res.code == 0) {
                    that.tableData = res.result.list;
                    that.total = res.result.total;
                }
            });
        },
        // 获取体验码
        getQRcode () {
            let that = this;
            let t = Date.now();
            let path = this.storeId ? this.$api.serviceProvider.wxqrCode : this.$api.set.wxqrCode;
            console.log(this.storeId)
            this.$axios.post(this.type == 'wx' ? path : this.type == 'ali' ? this.$api.set.experienceQuery : '' + `?t=${t}`, this.storeId ? { store_id: this.storeId } : {}).then(res => {
                if (res.code == 0) {
                    that.QRcode = res.result;
                    if (!res.result) {
                        that.aliexperienceCreate();
                    }
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
.el-input {
    max-width: 500px;
}

.itmeBox {
    display: flex;
    font-size: 14px;
    color: '#666666';
    margin-bottom: 15px;

    .title {
        width: 170px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-right: 20px;

        .star {
            padding-top: 8px;
            margin-right: 5px;
        }
    }

    .operation {
        display: flex;

        .addOperation {
            width: 90px;
            height: 35px;
            background: #3370ff;
            color: #fff;
            text-align: center;
            line-height: 35px;
            border-radius: 5px;
            cursor: pointer;
        }

        .outOperation {
            width: 90px;
            height: 35px;
            border: 1px solid #3370ff;
            color: #bbbbbb;
            text-align: center;
            line-height: 35px;
            border-radius: 5px;
            margin: 0 20px;
            cursor: pointer;
        }

        .manyOut {
            line-height: 35px;
            color: #3370ff;
            cursor: pointer;
        }
    }

    .codebox {
        color: #a7a7a7;

        .code {
            margin-bottom: 10px;

            img {
                width: 200px;
                height: 200px;
            }
        }
    }
}

.contantbox {
    margin-bottom: 10px !important;

    .title {
        width: 100px !important;
    }
}

.search {
    width: 80px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: #3370ff;
    color: #fff;
    border-radius: 5px;
    margin-left: 20px;
    cursor: pointer;
}

.paging {
    margin-top: 20px;
}

// .experiencerWxModal {
//   width: 50% !important;
// }
// .el-table {
//   width: 100% !important;
//   & /deep/ .el-table__header {
//     width: 100% !important;
//   }
//   & /deep/.el-table__body {
//     width: 100% !important;
//   }
// }
</style>
